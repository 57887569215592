.tui-editor-defaultUI-toolbar {
  border-radius: 8px 7px 0px 0px;
  background: #f3f5f6;
  margin-bottom: 8px;
}

.tui-editor-defaultUI-toolbar button {
  background-color: transparent;
  border: none;
}

.te-tab-section {
  display: none;
}

.te-mode-switch-section {
  display: none !important;
}
